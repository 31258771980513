import { faFolderPlus, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { getErrorMessage } from '../../../common/utils';
import { createCollection } from '../../../services/Collections';
import { Collection } from '../../../types';
import StyledDialog from '../../common/StyledDialog';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSave: (collection: Collection) => void;
}

const AddCollectionModal: React.FC<Props> = ({ isOpen, onClose, onSave }: Props) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const reset = () => {
    setName('');
    setDescription('');
  };

  const handleOnClose = () => {
    onClose();
    reset();
  };

  const handleOnCreate = async () => {
    if (!name.trim().length) {
      return toast.error('Collection name is required');
    }

    setIsSaving(true);
    try {
      const collection = await createCollection(name, description);
      toast.success('Collection Added');
      onSave(collection);
      reset();
    } catch (error) {
      console.error(error);
      return toast.error(getErrorMessage(error));
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <StyledDialog
      isOpen={isOpen}
      title="New Collection"
      closeText="Cancel"
      confirmText={
        isSaving ? (
          <div>
            <FontAwesomeIcon icon={faSpinner} className="animate-spin" /> Saving
          </div>
        ) : (
          'Save'
        )
      }
      disabled={isSaving}
      onConfirm={handleOnCreate}
      onClose={handleOnClose}
      //   width="w-1/2"
      icon={faFolderPlus}>
      <div>
        <div className="mt-2">
          <input
            type="text"
            autoComplete="off"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
            required
            placeholder="Collection Name"
            disabled={isSaving}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
        <div className="mt-2">
          <textarea
            placeholder="Collection description"
            rows={3}
            onChange={(e) => setDescription(e.currentTarget.value)}
            required
            disabled={isSaving}
            className="disabled:text-gray-400 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            value={description}
          />
        </div>
      </div>
    </StyledDialog>
  );
};

export default AddCollectionModal;
