import { faAnglesLeft, faAnglesRight, faCircleExclamation, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CodeEditor from '@uiw/react-textarea-code-editor';
import Handlebars from 'handlebars';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { getMessageContentString, isToolMessage } from '../../common/prompts';
import { deepCopy, deepEqual, getErrorMessage, toTitleCase } from '../../common/utils';
import { FullMessageContent } from '../../types/Models';
import { PlaygroundMessage, PromptMessageRole, ToolActionTypes } from '../../types/Prompt';
import { PromptPreviewFormatter, TabGroup } from '../common';

import '../../styles/editor.css';
import { HbsHelpersTab, PayloadTab, ToolsTab } from './playgroundc';
import PromptEditorMessage from './PromptEditorMessage';
import PromptEditorTool from './PromptEditorTool';

// TODO: handlebars precompile: https://handlebarsjs.com/api-reference/compilation.html#handlebars-precompile-template-options
// TODO: move the general layout of this up into playground message

interface Props {
  fullMessageContent: FullMessageContent | undefined;
  payload: Record<string, any>;
  hbsHelpers: Record<string, string>;
  disabled: boolean;
  showPreview?: boolean;
  className?: string;
  autoScroll?: boolean;

  onMessagesUpdate?(fullMessageContent: FullMessageContent, fullMessageContentEvaluated: FullMessageContent): void;
  onPayloadChange?(payload: any): void;
  onUnload?(payload: any): void;
}

/**
 * Component for composing and managing prompt messages.
 *
 * @component
 * @param {Props} props - The properties for the component.
 * @param {FullMessageContent} props.fullMessageContent - The full content of the messages.
 * @param {any} props.payload - The payload data for the messages.
 * @param {Record<string, string>} props.hbsHelpers - Handlebars helpers for message templates.
 * @param {boolean} props.disabled - Flag to disable the component.
 * @param {boolean} [props.showPreview=false] - Flag to show the preview of the messages.
 * @param {string} [props.className] - Additional class names for the component.
 * @param {boolean} [props.autoScroll=true] - Flag to auto scroll to the bottom of the messages.
 * @param {function} props.onMessagesUpdate - Callback function when messages are updated.
 * @param {function} props.onPayloadChange - Callback function when payload is changed.
 *
 * @returns {JSX.Element} The rendered component.
 */
const PromptComposerMessages: React.FC<Props> = ({
  fullMessageContent,
  payload,
  hbsHelpers,
  disabled,
  showPreview = false,
  className,
  autoScroll = true,
  onMessagesUpdate,
  onPayloadChange,
  onUnload
}: Props) => {
  const [isPreview, setIsPreview] = useState<boolean>(showPreview);
  const [_payload, setPayload] = useState<any>(payload);
  const [_hbsHelpers, setHbsHelpers] = useState<Record<string, string>>(hbsHelpers);
  const [_fullMessageContent, setFullMessageContent] = useState<FullMessageContent>();
  const [isShowSettings, setIsShowSettings] = useState<boolean>(true);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [hbsError, setHbsError] = useState<string>();

  const messagesBottomRef = useRef<HTMLDivElement>(null);
  const payloadRef = React.useRef<any | null>(null);
  payloadRef.current = _payload;

  useEffect(() => () => onUnload && onUnload(payloadRef.current), []);

  const collapseButton = (
    <div className="flex-1 text-right">
      <FontAwesomeIcon
        icon={faAnglesLeft}
        className="text-indigo-600 w-4 h-4 cursor-pointer"
        onClick={() => setIsShowSettings(false)}
      />
    </div>
  );

  useEffect(() => {
    if (!payload || !_fullMessageContent || deepEqual(_payload, payload)) return;

    try {
      const messagesEvaluated = getEvaluateMessages(_fullMessageContent, payload);
      onMessagesUpdate && onMessagesUpdate(_fullMessageContent, messagesEvaluated);
    } catch (e) {}

    setPayload(payload);
    setHbsError(undefined);
  }, [payload]);

  useEffect(() => {
    syncHelpers(_hbsHelpers, hbsHelpers);
    setHbsHelpers(hbsHelpers);
  }, [hbsHelpers]);

  useEffect(() => {
    if (!fullMessageContent || deepEqual(fullMessageContent, _fullMessageContent)) return;

    const newContent = deepCopy(fullMessageContent);

    // check if last message type is tool
    const lastMessage = newContent.messages.length ? newContent.messages[newContent.messages.length - 1] : undefined;

    // if last message is tool use, add a tool response message for the user
    if (lastMessage?.type === ToolActionTypes.TOOL_USE) {
      newContent.messages.push({
        type: ToolActionTypes.TOOL_RESULT,
        role: PromptMessageRole.USER,
        toolUseId: lastMessage.id,
        content: ''
      } as PlaygroundMessage);
    }

    setFullMessageContent(newContent);
    setIsPreview(false);
    setHbsError(undefined);
    onMessagesUpdate && onMessagesUpdate(newContent, getEvaluateMessages(newContent, _payload));

    scrollChatToBottom();
  }, [fullMessageContent]);

  const getEvaluateMessages = (messages: FullMessageContent, payload: any): FullMessageContent => {
    const templates = getHbsTemplates(messages);
    const messagesEvaluated = deepCopy(messages);
    try {
      messagesEvaluated.systemPrompt = templates[0](payload);
      for (let i = 1; i < templates.length; i++) {
        const t = messagesEvaluated.messages[i - 1].type;
        if (t !== ToolActionTypes.TOOL_USE && t !== ToolActionTypes.TOOL_RESULT) {
          messagesEvaluated.messages[i - 1].text = templates[i](payload);
        }
      }
      setHbsError(undefined);
    } catch (e) {
      let err = getErrorMessage(e).replace('\n', ' ').split('--')[0];
      setHbsError(err);
    }
    console.log('evald', messagesEvaluated);
    return messagesEvaluated;
  };

  const getHbsTemplates = (fullMessage?: FullMessageContent): HandlebarsTemplateDelegate[] => {
    const opts = { noEscape: true };

    return [
      Handlebars.compile(fullMessage?.systemPrompt ?? '', opts),
      ...(fullMessage?.messages.map((message) => Handlebars.compile(getMessageContentString(message), opts)) || [])
    ];
  };

  const handlePayloadChange = (newPayload: any): void => {
    setPayload(newPayload);
    onPayloadChange && onPayloadChange(newPayload);

    if (_fullMessageContent) {
      updateMessages(_fullMessageContent);
    }
  };

  const handleOnAddMessage = (): void => {
    if (!_fullMessageContent) return;

    const newContent = deepCopy(_fullMessageContent);
    newContent.messages.push({ role: PromptMessageRole.USER, text: '' } as PlaygroundMessage);
    updateMessages(newContent);
  };

  const handleSystemPromptChange = (newPrompt: string): void => {
    if (!_fullMessageContent) return;

    const newContent = deepCopy(_fullMessageContent);
    newContent.systemPrompt = newPrompt;
    updateMessages(newContent);
  };

  const handleMessageChange = (index: number, message: PlaygroundMessage, messageContent: string): void => {
    if (!_fullMessageContent) return;

    const newContent = deepCopy(_fullMessageContent);
    newContent.messages[index] = { ...message, text: messageContent };

    updateMessages(newContent);
  };

  const handleToolChange = (index: number, toolMessage: PlaygroundMessage): void => {
    if (!_fullMessageContent) return;

    const newContent = deepCopy(_fullMessageContent);
    newContent.messages[index] = toolMessage;

    updateMessages(newContent);
  };

  const handleMessageDelete = (index: number): void => {
    if (!_fullMessageContent) return;
    const newContent = deepCopy(_fullMessageContent);
    newContent.messages.splice(index, 1);
    updateMessages(newContent);
  };

  const syncHelpers = (previousHelpers: Record<string, string>, helpers: Record<string, string>): void => {
    Object.keys(previousHelpers).forEach((key) => {
      try {
        Handlebars.unregisterHelper(key);
      } catch (e) {
        console.warn(`Error unregistering helper`, e);
      }
    });

    Object.keys(helpers).forEach((key) => {
      try {
        Handlebars.registerHelper(key, eval(helpers[key]));
      } catch (e) {}
    });
  };

  const updateMessages = (newContent: FullMessageContent): void => {
    const messagesEvaluated = getEvaluateMessages(newContent, _payload);
    setFullMessageContent(newContent);
    try {
      onMessagesUpdate && onMessagesUpdate(newContent, messagesEvaluated);
    } catch (e) {}
  };

  // FIXME: this is a hack to scroll to the bottom of the chat, fix this later
  const scrollChatToBottom = () => {
    if (!autoScroll) return;

    if (messagesBottomRef?.current) {
      setTimeout(() => {
        messagesBottomRef?.current?.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    }
  };

  return (
    <div className={`flex gap-x-4 w-full h-[calc(100vh-320px)] ${className ?? ''}`}>
      {!isShowSettings && (
        <div className="cursor-pointer" onClick={() => setIsShowSettings(true)}>
          <FontAwesomeIcon icon={faAnglesRight} className="text-indigo-600 w-4 h-4" />
        </div>
      )}
      {isShowSettings && (
        <div className="w-1/3 max-w-[22.5rem]" data-color-mode="light">
          <TabGroup
            tabNames={[
              'Payload',
              <div key={2} className="grid grid-cols-2 gap-x-1.5">
                <div>Tools</div>
                <div className="bg-indigo-400 rounded-md flex items-center justify-center text-white text-xs w-5 my-0.5">
                  {Object.keys(_fullMessageContent?.tools || {}).length}
                </div>
              </div>,
              <div key={2} className="grid grid-cols-2 gap-x-1.5">
                <div>Helpers</div>
                <div className="bg-indigo-400 rounded-md flex items-center justify-center text-white text-xs w-5 my-0.5">
                  {Object.keys(_hbsHelpers || {}).length}
                </div>
              </div>
            ]}
            selectedTabIndex={selectedTab}
            onTabChange={setSelectedTab}
            extraTabHeaderContent={collapseButton}>
            <div className="h-[calc(100%-52px)]">
              {selectedTab === 0 && (
                <PayloadTab payload={_payload} disabled={disabled} onPayloadChange={handlePayloadChange} />
              )}
              {selectedTab === 1 && <ToolsTab tools={_fullMessageContent?.tools ?? []} />}
              {selectedTab === 2 && <HbsHelpersTab hbsHelpers={_hbsHelpers} />}
            </div>
          </TabGroup>
        </div>
      )}
      <div className={`h-full border-l border-gray-200 ${isShowSettings ? 'w-[calc(100%-22.5rem)]' : 'w-full'}`}>
        <div className="h-[calc(100%-10px)] overflow-y-scroll text-xs pl-1">
          {!isPreview && (
            <div>
              <div className="mb-4">
                <div className="mx-2 text-gray-500 font-semibold">System</div>
                {_fullMessageContent?.systemPrompt && (
                  <CodeEditor
                    value={_fullMessageContent.systemPrompt}
                    language="handlebars"
                    placeholder=""
                    onChange={(e) => handleSystemPromptChange(e.target.value)}
                    padding={10}
                    disabled={disabled}
                    className="h-full min-h-[125px] resize-none border-none p-3 text-md focus:ring-0 prompt-editor mt-2 mx-2"
                    style={{
                      backgroundColor: '#fff'
                    }}
                  />
                )}
              </div>
              {_fullMessageContent &&
                _fullMessageContent.messages.map((m: PlaygroundMessage, i: number) => (
                  <div key={i}>
                    {isToolMessage(m) ? (
                      <PromptEditorTool
                        message={m}
                        toolActionType={m.type}
                        disabled={disabled}
                        onMessageDelete={() => handleMessageDelete(i)}
                        onMessageChange={(m) => handleToolChange(i, m)}
                        className="mx-2"
                      />
                    ) : (
                      <PromptEditorMessage
                        message={{ role: m.role, content: getMessageContentString(m) }}
                        disabled={disabled}
                        onMessageDelete={() => handleMessageDelete(i)}
                        onMessageChange={(v) => handleMessageChange(i, m, v.content)}
                        readOnly={m.type === ToolActionTypes.TOOL_USE}
                        className="mx-2"
                      />
                    )}
                  </div>
                ))}
              <div className="w-full text-center my-4">
                {!disabled ? (
                  <button className="standard secondary" onClick={(e) => handleOnAddMessage()}>
                    <FontAwesomeIcon icon={faPlus} className="mr-2" />
                    Add Message
                  </button>
                ) : (
                  <div className="flex space-x-2 justify-center items-center mt-6">
                    <div className="h-3 w-3 bg-indigo-700 rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                    <div className="h-3 w-3 bg-indigo-700 rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                    <div className="h-3 w-3 bg-indigo-700 rounded-full animate-bounce"></div>
                  </div>
                )}
              </div>
              <div ref={messagesBottomRef}></div>
            </div>
          )}
          {isPreview && (
            <div className="composer-highlighter">
              <div className="m-2 mb-4">
                <div className="text-gray-500 font-semibold">System</div>
                {_fullMessageContent && (
                  <PromptPreviewFormatter
                    template={_fullMessageContent.systemPrompt}
                    payload={_payload}
                    className="p-2"
                    onError={(e) => toast.error(e)}
                  />
                )}
              </div>
              {_fullMessageContent &&
                _fullMessageContent.messages.map((m: PlaygroundMessage, i: number) => (
                  <div key={i} className="m-2 mb-4">
                    <div className="text-gray-500 font-semibold">{toTitleCase(m.role)}</div>
                    <PromptPreviewFormatter
                      template={getMessageContentString(m)}
                      payload={_payload}
                      className="p-2"
                      onError={(e) => toast.error(e)}
                    />
                  </div>
                ))}
            </div>
          )}
        </div>
        <div className="flex mx-2 py-2">
          <div className="flex-1 text-left pt-1.5">
            {hbsError && (
              <div className="text-sm text-red-600">
                <FontAwesomeIcon icon={faCircleExclamation} className="inline" /> <span>{hbsError}</span>
              </div>
            )}
          </div>
          <div className="flex items-center justify-end">
            <div className="text-sm text-gray-500 mr-2">Preview</div>
            <input
              type="checkbox"
              checked={isPreview}
              className="peer sr-only opacity-0"
              id="toggle"
              onChange={() => setIsPreview(!isPreview)}
            />
            <label htmlFor="toggle" className="selector mt-1" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromptComposerMessages;
