import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { generatePromptPayloadFromVersion } from '../../common/prompts';
import { PromptVersion } from '../../types/Prompt';
import { StyledDialog } from '../common';

/**
 * Props for the PromptDataStructure component.
 */
interface Props {
  version: PromptVersion | undefined;
  isOpen: boolean;
  onClose: () => void;
}

/**
 * Component for displaying the data structure of a prompt.
 *
 * @component
 * @param {Props} props - The component props.
 * @param {version} props.version - The prompt version.
 * @param {boolean} props.isOpen - Whether the data structure is open or not.
 * @param {() => void} props.onClose - The callback function to handle the close event.
 * @returns {JSX.Element} The rendered component.
 */
const PromptDataStructure: React.FC<Props> = ({ version, isOpen, onClose }: Props) => {
  const [structure, setStructure] = useState<string>('');

  useEffect(() => {
    if (!version) return;

    setStructure(JSON.stringify(generatePromptPayloadFromVersion(version), null, 2));
  }, [version]);

  return (
    <StyledDialog
      title="Data Structure"
      isOpen={isOpen}
      closeText="Close"
      icon={faLayerGroup}
      width="w-2/5"
      confirmText={
        <CopyToClipboard
          text={structure}
          onCopy={() => {
            toast.success('Code copied to clipboard!');
          }}>
          <div>
            <FontAwesomeIcon icon={faCopy} className="h-4 w-4 mr-1 inline-block" />
            Copy
          </div>
        </CopyToClipboard>
      }
      onConfirm={() => {}}
      onClose={() => onClose()}>
      <div className="!text-sm">
        <SyntaxHighlighter language="handlebars" style={docco} showLineNumbers>
          {structure}
        </SyntaxHighlighter>
      </div>
    </StyledDialog>
  );
};

export default PromptDataStructure;
