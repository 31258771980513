import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { memo, useEffect, useState } from 'react';
import { ModelParameter as Parameter, ModelParameterValue } from '../../types/Models';
/**
 * Props for the ModelParameter component.
 */
interface Props {
  property: Parameter;
  value: number | boolean;
  disabled: boolean;
  onPropertyChange(parameter: ModelParameterValue): void;
}

/**
 * Represents a model parameter component.
 *
 * @component
 * @param {Props} props - The component props.
 * @param {Parameter} props.property - The model parameter.
 * @param {number | boolean} props.value - The value of the model parameter.
 * @param {boolean} props.disabled - Indicates whether the model parameter is disabled.
 * @param {Function} props.onPropertyChange - The callback function to handle parameter changes.
 * @returns {JSX.Element} The model parameter component.
 */
const ModelParameter: React.FC<Props> = memo(({ property, value, disabled, onPropertyChange }: Props) => {
  const [inputValue, setInputValue] = useState<number | boolean>(value);
  const [showInputField, setShowInputField] = useState<boolean>(false);
  const [fieldValue, setFieldValue] = useState<string>(value.toString());

  useEffect(() => setInputValue(value), [value]);
  useEffect(() => setFieldValue(inputValue.toString()), [inputValue]);

  const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const val = Number(e.target.value);
    if (isNaN(val)) {
      return;
    }

    setNumberValue(val);
  };

  const setNumberValue = (val: number): boolean => {
    if (val < property.min!) return false;
    else if (val > property.max!) return false;

    setInputValue(val);
    onPropertyChange({ name: property.parameterModel, value: val });

    return true;
  };

  const updateValueFromField = (): void => {
    const val = Number(fieldValue);
    if (isNaN(val)) {
      setFieldValue(inputValue.toString());
      return;
    }

    setShowInputField(false);
    if (!setNumberValue(val)) {
      setFieldValue(inputValue.toString());
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onPropertyChange({ name: property.parameterModel, value: e.target.checked });
  };

  let field;
  if (property.type === 'integer' || property.type === 'float') {
    const step = property.type === 'float' ? 0.1 : 1;
    const typedValue: number = Number(inputValue);

    field = (
      <div className="flex items-center">
        <input
          type="range"
          className={`transparent h-[4px] flex-grow cursor-pointer appearance-none border-transparent accent-indigo-500 bg-neutral-200 dark:bg-neutral-600 ${showInputField ? 'max-w-24' : ''}`}
          min={property.min}
          max={property.max}
          step={step}
          value={typedValue}
          onChange={handleSliderChange}
          disabled={disabled}
        />
        {!showInputField ? (
          <div
            className="text-xs text-right text-gray-500 pl-2 cursor-pointer hover:text-indigo-950"
            onClick={() => setShowInputField(true)}>
            {new Intl.NumberFormat().format(Number(inputValue))}
          </div>
        ) : (
          <div>
            <input
              className="w-12 ml-2 p-1.5 text-xs rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 inline"
              value={fieldValue}
              onChange={(e) => setFieldValue(e.target.value)}
            />
            <FontAwesomeIcon
              icon={faCheck}
              className="text-green-500 ml-2 cursor-pointer inline"
              onClick={() => updateValueFromField()}
            />
          </div>
        )}
      </div>
    );
  } else {
    field = (
      <div>
        <input
          type="checkbox"
          checked={Boolean(inputValue)}
          className="peer sr-only opacity-0"
          id="toggle"
          onChange={handleCheckboxChange}
          disabled={disabled}
        />
        <label htmlFor="toggle" className="selector mt-1" />
      </div>
    );
  }

  return (
    <div className="py-2 border-b last:border-0 border-gray-300 w-full px-3">
      <div className="text-xs text-gray-600 ">{property.name}</div>
      {field}
    </div>
  );
});

ModelParameter.displayName = 'Model Parameter';

export default ModelParameter;
