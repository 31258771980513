import { Navigate, Outlet } from 'react-router-dom';
import { getUserLocal } from '../services/User';

const DefaultLayout = () => {
  const basicUserInfo = getUserLocal();

  if (basicUserInfo?.active) {
    return <Navigate replace to={'/'} />;
  }

  return <Outlet />;
};

export default DefaultLayout;
