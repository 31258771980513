import { toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { history } from '../lib/history';
import { getUserLocal, logout } from '../services/User';

const ProtectedNoLayout = () => {
  const basicUserInfo = getUserLocal();
  const { oktaAuth, authState } = useOktaAuth();

  useEffect(() => {
    if ((!authState || !authState?.isAuthenticated) && !basicUserInfo?.active) {
      logout();
      history.replace(toRelativeUrl('/login', window.location.origin));
    }
  }, [oktaAuth, !!authState, authState?.isAuthenticated, basicUserInfo]);

  return <Outlet />;
};

export default ProtectedNoLayout;
