import { faGrip, faList, faPlus, faTerminal, faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import Select, { MultiValue } from 'react-select';
import makeAnimated from 'react-select/animated';
import { isLibraryItemType, userCanManageLibraryType } from '../../common/prompts';
import { getErrorMessage } from '../../common/utils';
import { ForkPromptModal, PromptListRow, PromptTile } from '../../components';
import { PV } from '../../components/common/PromptVersionSelector';
import { favoritePrompt, getPrompts, unfavoritePrompt } from '../../services/Prompts';
import { getUserLocal, isAdmin as isUserAdmin } from '../../services/User';
import { Prompt } from '../../types';
import { PromptRoles, PromptTypes } from '../../types/Prompt';
import { AddCollectionModal, CollectionList } from './collections';

interface Props {
  type: PromptTypes;
  active?: boolean;
  additonalButton?: React.ReactElement[] | React.ReactElement;
}

const filterOptions = [
  { value: 'active', label: 'Active' },
  { value: 'favorite', label: 'Favorites' },
  { value: 'mine', label: 'Mine' },
  { value: 'archived', label: 'Archived' }
];

const animatedComponents = makeAnimated();

/**
 * Renders the Prompts list.
 *
 * @param {Props} props - The component props.
 * @param {string} props.type - The type of prompt.
 * @param {boolean} props.active - The active state of the prompt.
 * @param {React.ReactElement[]} props.additonalButton - Additional buttons to render.
 * @returns {JSX.Element} The rendered Prompts page.
 */
const PromptList: React.FC<Props> = ({ type, active = true, additonalButton }: Props) => {
  const navigate = useNavigate();

  const [prompts, setPrompts] = useState<Prompt[]>([]);
  const [promptsFiltered, setPromptsFiltered] = useState<Prompt[]>([]);
  const [isPromptsLoading, setIsPromptsLoading] = useState<boolean>(true);
  const [filters, setFilters] = useState<string[]>([filterOptions[0].value]);
  const [isShowForkModal, setIsShowForkModal] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isShowAllPrompts, setIsShowAllPrompts] = useState<boolean>(false);
  const [isCardView, setIsCardView] = useState<boolean>(true);
  const [isShowNewCollectionModal, setIsShowNewCollectionModal] = useState<boolean>(false);
  const [filterText, setFilterText] = useState<string>('');
  const [forkPrompt, setForkPrompt] = useState<Prompt>();
  const [promptType, setPromptType] = useState<PromptTypes>(type);
  const [userCanAddPrompt, setUserCanAddPrompt] = useState<boolean>(false);
  const [refreshCollectionTrigger, setRefreshCollectionTrigger] = useState(0);
  const [selectedCollectionId, setSelectedCollectionId] = useState<string>();

  useEffect(() => {
    setPromptType(type);

    if (!active || prompts.length) return;

    setUserCanAddPrompt(!isLibraryItemType(type) || userCanManageLibraryType(type, getUserLocal()!));
    setIsAdmin(isUserAdmin());

    (async () => {
      try {
        setPrompts(await getPrompts(promptType));
      } catch (error) {
        return toast.error(getErrorMessage(error));
      } finally {
        setIsPromptsLoading(false);
      }
    })();
  }, [type, active]);

  useEffect(() => {
    if (!prompts) return;

    setPromptsFiltered(
      prompts.filter((prompt) => {
        const matchesText =
          prompt.name.toLowerCase().includes(filterText.toLowerCase()) ||
          prompt.description.toLowerCase().includes(filterText.toLowerCase());

        const matchesFilters =
          (!filters.includes('active') || !prompt.archived) &&
          (!filters.includes('favorite') || prompt.isFavorite) &&
          (!filters.includes('mine') || prompt.userRole === PromptRoles.OWNER) &&
          (!filters.includes('archived') || prompt.archived) &&
          (selectedCollectionId !== undefined ? prompt.collectionId === selectedCollectionId : true);

        return matchesText && matchesFilters;
      })
    );
  }, [prompts, filters, filterText, selectedCollectionId]);

  const handleOnPromptEditClick = (prompt: Prompt) => {
    navigate(`/prompts/${prompt.id}/manage`, { state: { prompt } });
  };

  const handleOnPromptForkClick = (prompt: Prompt) => {
    setForkPrompt(prompt);
    setIsShowForkModal(true);
  };

  const handleOnStarClick = async (prompt: Prompt) => {
    try {
      if (prompt.isFavorite) {
        await unfavoritePrompt(prompt.id!);
      } else {
        await favoritePrompt(prompt.id!);
      }
    } catch (error) {
      return toast.error(getErrorMessage(error));
    }

    setPrompts((prevPrompts) =>
      prevPrompts.map((p) => {
        if (p.id === prompt.id) {
          return { ...p, isFavorite: !p.isFavorite };
        }
        return p;
      })
    );
  };

  const handleOnPromptForked = (promptVersion: PV) => {
    setForkPrompt(undefined);
    setIsShowForkModal(false);
    navigate(`/prompts/${promptVersion.prompt?.id}`, { state: { promptVersion } });
  };

  const handleFilterOptionChange = (selected: MultiValue<unknown>) => {
    const options = selected as Record<string, string>[];

    if (selected.length === 0) {
      setFilters([]);
      return;
    }

    setFilters(options.map((o) => o.value));
  };

  const handleShowAllPrompts = async () => {
    setIsShowAllPrompts(!isShowAllPrompts);
    setPrompts([]);
    setIsPromptsLoading(true);

    try {
      setPrompts(await getPrompts(promptType, !isShowAllPrompts));
    } catch (error) {
      return toast.error(getErrorMessage(error));
    } finally {
      setIsPromptsLoading(false);
    }
  };

  const handleCollectionSelect = (collectionId: string | undefined) => {
    setSelectedCollectionId(collectionId);
  };

  return (
    <>
      <div className="mx-auto">
        {!isPromptsLoading && prompts.length > 0 && (
          <div className="flex items-center mt-2">
            <input
              id="filter"
              name="filter"
              type="search"
              autoComplete="off"
              value={filterText}
              placeholder="Filter"
              onChange={(e) => setFilterText(e.currentTarget.value)}
              required
              disabled={isPromptsLoading}
              className="disabled:text-gray-400 block w-64 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mr-4"
            />
            {(type === PromptTypes.PROMPT || type === PromptTypes.EVALUATOR) && (
              <Select
                isMulti
                name="filters"
                options={filterOptions}
                isDisabled={isPromptsLoading}
                isSearchable={false}
                classNamePrefix="promptly"
                components={animatedComponents}
                // className="w-60"
                placeholder="View Options"
                value={filterOptions.filter((o) => filters.includes(o.value))}
                menuPortalTarget={document.body}
                onChange={handleFilterOptionChange}
              />
            )}
            {isAdmin && (
              <FontAwesomeIcon
                icon={faUserSecret}
                onClick={handleShowAllPrompts}
                className={`w-8 h-8 hover:text-indigo-800 cursor-pointer ml-4 ${isShowAllPrompts ? 'text-indigo-800' : 'text-gray-500'}`}
              />
            )}
            <FontAwesomeIcon
              icon={isCardView ? faGrip : faList}
              onClick={() => setIsCardView(!isCardView)}
              className="w-8 h-8 hover:text-indigo-800 cursor-pointer ml-4"
            />

            <div className="flex-1 text-right">
              {userCanAddPrompt && (
                <div>
                  <button className="standard secondary mr-2" onClick={() => setIsShowNewCollectionModal(true)}>
                    <FontAwesomeIcon icon={faPlus} className="mr-1" />
                    New Collection
                  </button>
                  <a href={`/prompts/new/${promptType.toLowerCase()}`}>
                    <button className="standard">
                      <FontAwesomeIcon icon={faPlus} className="mr-1" />
                      New Prompt
                    </button>
                  </a>
                </div>
              )}
              {additonalButton ?? null}
            </div>
          </div>
        )}

        {isCardView && (
          <div>
            {promptType === PromptTypes.PROMPT && (
              <CollectionList
                active={active}
                refreshTrigger={refreshCollectionTrigger}
                onCollectionSelect={handleCollectionSelect}
              />
            )}
            <h2 className="text-lg font-semibold text-gray-700 mb-1 mt-6">Prompts</h2>
            <ul className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4 rounded-lg">
              {isPromptsLoading ? (
                <>
                  <PromptTile />
                  <PromptTile />
                </>
              ) : (
                promptsFiltered.map((prompt: Prompt) => (
                  <PromptTile
                    key={prompt.id}
                    prompt={prompt}
                    onManageClick={handleOnPromptEditClick}
                    onForkClick={handleOnPromptForkClick}
                    onStarClick={handleOnStarClick}
                    onClick={(prompt: Prompt) => navigate(`/prompts/${prompt.id}`, { state: { prompt } })}
                  />
                ))
              )}
            </ul>
          </div>
        )}

        {!isCardView && (
          <ul className="rounded-lg border border-slate-200 bg-white shadow-sm divide-y divide-gray-200 mt-6 w-2/3 mx-auto">
            {isPromptsLoading ? (
              <>
                <PromptListRow />
                <PromptListRow />
              </>
            ) : (
              promptsFiltered.map((prompt: Prompt) => (
                <PromptListRow
                  key={prompt.id}
                  prompt={prompt}
                  onManageClick={handleOnPromptEditClick}
                  onForkClick={handleOnPromptForkClick}
                  onStarClick={handleOnStarClick}
                  onClick={(prompt: Prompt) => navigate(`/prompts/${prompt.id}`, { state: { prompt } })}
                />
              ))
            )}
          </ul>
        )}

        {!isPromptsLoading && !promptsFiltered.length && (
          <div className="mt-5 text-gray-700">
            {selectedCollectionId ? (
              <div>
                <div>It looks like there are no prompts in this category that match your filters.</div>
              </div>
            ) : (
              <div>
                <div className="mt-2 text-indigo-500 mx-auto text-center">
                  <FontAwesomeIcon icon={faTerminal} className="w-40 h-40" />
                </div>
                <div className="mt-5 text-gray-700 mx-auto text-center">
                  <div>
                    It looks like you haven&lsquo;t created any prompts yet. You can{' '}
                    <a role="button" href={`/prompts/new/${promptType.toLowerCase()}`}>
                      create a new prompt
                    </a>{' '}
                    or browse the Prompt Library.
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <ForkPromptModal
        isOpen={isShowForkModal}
        prompt={forkPrompt}
        onClose={() => setIsShowForkModal(false)}
        onSave={handleOnPromptForked}
      />

      <AddCollectionModal
        isOpen={isShowNewCollectionModal}
        onClose={() => setIsShowNewCollectionModal(false)}
        onSave={() => {
          setRefreshCollectionTrigger((prev) => prev + 1);
          setIsShowNewCollectionModal(false);
        }}
      />
    </>
  );
};

export default PromptList;
