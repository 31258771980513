import { useEffect } from 'react';

const DocsPage = () => {
  useEffect(() => {
    const iframe = document.createElement('iframe');
    iframe.src = '/__promptly_docs/index.html';
    iframe.style.width = '100%';
    iframe.style.height = '100vh';
    iframe.style.border = 'none';
    document.getElementById('docs-container')?.appendChild(iframe);

    return () => {
      document.getElementById('docs-container')?.removeChild(iframe);
    };
  }, []);

  return <div id="docs-container"></div>;
};

export default DocsPage;
