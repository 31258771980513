import { PromptMessageRole } from './types/Prompt';

export const COOKIE_NAME = 'promptly';
export const PROMPT_MESSAGE_ROLES = [
  { id: PromptMessageRole.USER, label: 'User' },
  { id: PromptMessageRole.ASSISTANT, label: 'Assistant' }
];
export const DEFAULT_DF_FORMAT = 'MMM d, h:mm a';
export const DEFAULT_DF_FORMAT_NOTIME = 'MMM d';
export const DEFAULT_DF_FORMAT_SHORT = 'MM/dd h:mm a';
export const CHART_COLORS = [
  '#C4B5FD',
  '#8B5CF6',
  '#4338CA',
  '#312E81',
  '#6D28D9',
  '#7C3AED',
  '#7C3AED',
  '#6D28D9',
  '#818CF8',
  '#A78BFA'
];
export const SHARED_PROMPT_PATH = '/p';
