import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getPromptVersionShare } from '../../services/PromptVersions';

import { toRelativeUrl } from '@okta/okta-auth-js';
import toast from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { getErrorMessage, toTitleCase } from '../../common/utils';
import { history } from '../../lib/history';
import { PromptMessage, PromptVersionShared, PromptVersionTypes } from '../../types/Prompt';

interface Props {}

const syntaxStyles = { fontSize: '0.9em', padding: '0.7em' };

const SharedPrompt: React.FC<Props> = ({}: Props) => {
  const routerParams = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [version, setVersion] = useState<PromptVersionShared>();

  useEffect(() => {
    (async () => {
      const sharedPromptId = routerParams.sharedPromptId;

      if (!sharedPromptId) {
        return history.replace(toRelativeUrl('/', window.location.origin));
      }

      setIsLoading(true);

      try {
        setVersion(await getPromptVersionShare(sharedPromptId));
        setIsLoading(false);
      } catch (error) {
        toast.error(getErrorMessage(error));
        return history.replace(toRelativeUrl('/', window.location.origin));
      }
    })();
  }, []);

  return (
    <div className="mx-auto w-3/4 my-4 bg-gray-50 border-gray-300 border-2 p-3 rounded-lg">
      <h1 className="kanit-bold mx-auto text-center text-4xl text-gray-700">
        <span className="text-indigo-500 group-hover:text-black">&#123;</span> Promptly{' '}
        <span className="text-indigo-500 group-hover:text-black">&#125;</span>
      </h1>

      <div className="flex justify-between">
        <div className="flex-1 mb-2">
          {isLoading ? (
            <Skeleton className="!w-48" />
          ) : (
            <div className="pt-2.5 text-lg font-medium text-gray-600">{version?.name}</div>
          )}
        </div>
        <div>
          {isLoading ? (
            <Skeleton className="!w-12" />
          ) : (
            <div className="rounded-full bg-indigo-600 text-white pt-0.5 mt-3 text-sm font-semibold h-6 px-3 mr-3 text-center">
              v{version?.version}
            </div>
          )}
        </div>
      </div>

      <div className="mt-2 pt-5 border-t border-gray-100 ">
        {isLoading && !version ? (
          <Skeleton count={12} />
        ) : (
          <div>
            {version?.type === PromptVersionTypes.MESSAGING ? (
              <div>
                <div className="mb-4">
                  <div className="text-gray-500 font-semibold mb-1">System Prompt</div>
                  <SyntaxHighlighter customStyle={syntaxStyles} language="handlebars" wrapLines>
                    {version.messageTemplate?.systemPrompt || 'No System Prompt'}
                  </SyntaxHighlighter>
                </div>
                {version.messageTemplate?.messages.map((message: PromptMessage, i: number) => (
                  <div key={i} className="mb-4">
                    <div className="text-gray-500 font-semibold mb-1">{toTitleCase(message.role)}</div>
                    <SyntaxHighlighter customStyle={syntaxStyles} language="handlebars" wrapLines>
                      {message.content}
                    </SyntaxHighlighter>
                  </div>
                ))}
              </div>
            ) : (
              <SyntaxHighlighter customStyle={syntaxStyles} language="handlebars" wrapLines>
                {version?.template || ''}
              </SyntaxHighlighter>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SharedPrompt;
