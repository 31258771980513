import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import {
  faCircleMinus,
  faCirclePlus,
  faCircleQuestion,
  faSkullCrossbones,
  faSpinner
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useParams } from 'react-router';
import { formatRole } from '../../common/prompts';
import { getErrorMessage } from '../../common/utils';
import { AddUserModal, StyledDialog } from '../../components';
import DataTable, { TableData } from '../../components/common/Datatable';
import Selector, { SelectorValue } from '../../components/common/Selector';
import {
  addUserToCollection,
  //   deleteCollection,
  getCollection,
  removeUserFromCollection,
  setUserRoleInCollection
} from '../../services/Collections';
import { getUserByEmail, getUserLocal } from '../../services/User';
import { Collection, PromptUser, Roles } from '../../types';

interface Props {}

export const manageUserSelectorValues: { [key in Roles]: SelectorValue } = {
  [Roles.OWNER]: { value: Roles.OWNER, label: 'Owner' },
  [Roles.READ_WRITE]: { value: Roles.READ_WRITE, label: 'Contributor' },
  [Roles.READ_ONLY]: { value: Roles.READ_ONLY, label: 'Read Only' },
  [Roles.ADMIN]: { value: Roles.ADMIN, label: 'Admin' }
};

/**
 * Manage collection page component.
 *
 * @component
 * @param {Props} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
const ManageCollection: React.FC<Props> = ({}: Props) => {
  const navigate = useNavigate();
  const collectionId = useParams().collectionId;
  const user = getUserLocal();

  const [selectedUser, setSelectedUser] = useState<PromptUser>();
  const [collection, setCollection] = useState<Collection>();
  const [tableData, setTableData] = useState<TableData>();
  const [canManage, setCanManage] = useState<boolean>();
  const [deleteConfirmText, setDeleteConfirmText] = useState<string>('');

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowAddUser, setIsShowAddUser] = useState<boolean>(false);
  const [isShowRemoveModal, setIsShowRemoveModal] = useState<boolean>(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
  const [isCollectionDeleting, setIsCollectionDeleting] = useState<boolean>(false);
  const [isUsersAdding, setIsUsersAdding] = useState<boolean>(false);
  const [isModifyingUsers, setIsModifyingUsers] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);

    (async () => {
      await loadData();
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (!collection) return;

    // const multipleOwners = collection.users.filter((u) => u.collectionRole === Roles.OWNER).length > 1;
    setTableData({
      headers: ['Name', 'Email', 'Role', ''],
      rows: collection.users.map((user) => ({
        cols: [
          { value: user.name },
          { value: user.email },
          { value: generateActionsSelector(user) },
          {
            value:
              !canManage || user.id === getUserLocal()?.id ? (
                ''
              ) : (
                <FontAwesomeIcon
                  icon={faCircleMinus}
                  className="h-5 w-5 text-red-500 hover:text-red-400 hover:cursor-pointer transition ease-in-out hover:scale-125 duration-200"
                  onClick={() => {
                    setSelectedUser(user);
                    setIsShowRemoveModal(true);
                  }}
                />
              )
          }
        ]
      }))
    });
  }, [collection]);

  const loadData = async () => {
    try {
      const [_collection] = await Promise.all([getCollection(collectionId!)]);

      setCollection(_collection);
      console.log({ _collection, user });
      setCanManage(_collection.owners.find((owner) => owner.id === user?.id) !== undefined);
    } catch (error) {
      console.error(error);
      return toast.error(getErrorMessage(error));
    }
  };

  const generateActionsSelector = (au: PromptUser) => {
    return user!.id === au.id || !canManage ? (
      <span>{formatRole(au.promptRole)}</span>
    ) : (
      <Selector
        key={au.id}
        classNames="w-40 !hover:z-0"
        values={Object.values(manageUserSelectorValues).filter((value) => value.value !== Roles.ADMIN)}
        defaultValue={manageUserSelectorValues[au.promptRole]}
        disabled={isModifyingUsers}
        onChange={(selectorValue) => onRoleChange(au, selectorValue)}
      />
    );
  };

  const onRoleChange = async (user: PromptUser, roleSelector: SelectorValue) => {
    setIsModifyingUsers(true);

    try {
      await setUserRoleInCollection(collectionId!, user.id, roleSelector.value as Roles);
    } catch (e) {
      return toast.error(getErrorMessage(e));
    } finally {
      setIsModifyingUsers(false);
    }
  };

  const onAddUser = async (email: string, promptRole: Roles) => {
    if (!collection) return;

    setIsUsersAdding(true);
    setIsLoading(true);

    try {
      const user = await getUserByEmail(email);
      await addUserToCollection(collection.id!, user.id, promptRole);
      setCollection({ ...collection, users: collection.users.concat([{ ...user, promptRole, isCollection: true }]) });
      toast.success('User Added');
    } catch (e) {
      const err = getErrorMessage(e);
      if (err.includes('already exists')) {
        toast.error('User already has access to this collection.');
      } else {
        toast.error(err);
      }
    } finally {
      setIsLoading(false);
      setIsUsersAdding(false);
      setIsShowAddUser(false);
    }
  };

  const handleOnRemove = async () => {
    if (!collectionId || !selectedUser) return;

    setIsModifyingUsers(true);
    try {
      await removeUserFromCollection(collectionId, selectedUser.id);
    } catch (e) {
      return toast.error(getErrorMessage(e));
    } finally {
      setIsModifyingUsers(false);
      setIsShowRemoveModal(false);
    }

    setCollection({ ...collection!, users: collection!.users.filter((u) => u.id !== selectedUser!.id) });
  };

  const handleOnDelete = async () => {
    if (deleteConfirmText !== collection?.name) return;

    setIsCollectionDeleting(true);

    try {
      alert('TODO: deleteCollection');
      // await deleteCollection(collectionId!);
    } catch (e) {
      return toast.error(getErrorMessage(e));
    } finally {
      setIsCollectionDeleting(false);
      setIsShowDeleteModal(false);
      setDeleteConfirmText('');
      navigate(`/prompts/list`);
    }
  };

  return (
    <>
      <div className="mx-auto max-w-2xl">
        <div className="flex">
          <h1 className="text-2xl flex-1 font-semibold leading-7 text-gray-800">{collection?.name}</h1>
          <a href="/help/#/collection_manage" target="_blank" rel="noreferrer">
            <button className="help">
              <FontAwesomeIcon icon={faCircleQuestion} className="inline-block" />
            </button>
          </a>
        </div>
        <div className="mt-8">
          <div className="flex items-center">
            <h2 className="flex-1 text-base font-semibold leading-7 text-gray-800">Users</h2>
            {canManage && (
              <button
                disabled={isLoading || isModifyingUsers}
                onClick={() => setIsShowAddUser(true)}
                className="cursor-pointer">
                <FontAwesomeIcon
                  icon={faCirclePlus}
                  className="h-7 w-7 text-indigo-600 hover:text-indigo-500 hover:cursor-pointer transition ease-in-out hover:scale-125 duration-200"
                />
              </button>
            )}
          </div>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Other users who can access this collection and their roles. Owners can manage users and collection settings.
          </p>
          <div className="col-span-full mt-2">
            {isLoading ? <Skeleton count={5} /> : <DataTable data={tableData} />}
          </div>
        </div>
        {canManage && (
          <div className="mt-8">
            <h2 className="flex-1 text-base font-semibold leading-7 text-gray-800">Actions</h2>
            <div className="mt-2">
              <button className="danger" onClick={() => setIsShowDeleteModal(true)} disabled={isModifyingUsers}>
                Delete
              </button>
            </div>
          </div>
        )}
      </div>

      {collection && canManage && (
        <AddUserModal
          existingEmails={collection.users.map((u) => u.email)}
          isOpen={isShowAddUser}
          isSaving={isUsersAdding}
          onSave={onAddUser}
          onClose={() => setIsShowAddUser(false)}
        />
      )}

      <StyledDialog
        isOpen={isShowRemoveModal}
        title="Remove User"
        closeText="Cancel"
        confirmText={
          isModifyingUsers ? (
            <div>
              <FontAwesomeIcon icon={faSpinner} className="animate-spin mr-2" />
              Removing
            </div>
          ) : (
            'Remove'
          )
        }
        confirmClassName="bg-red-500 hover:!bg-red-400"
        icon={faTrashCan}
        width="w-1/4"
        disabled={isLoading}
        onClose={() => setIsShowRemoveModal(false)}
        onConfirm={handleOnRemove}>
        <div>
          <p>Are you sure you want to remove {selectedUser?.name} from this collection?</p>
          <p className="text-sm mt-2">
            {selectedUser?.name} will no longer have access to any prompts within this collection.
          </p>
        </div>
      </StyledDialog>

      <StyledDialog
        isOpen={isShowDeleteModal}
        title="Delete Collection"
        closeText="Cancel"
        confirmText={
          isCollectionDeleting ? (
            <div>
              <FontAwesomeIcon icon={faSpinner} className="animate-spin mr-2" /> Deleting
            </div>
          ) : (
            'Delete'
          )
        }
        confirmClassName="!bg-red-500 hover:!bg-red-400"
        confirmDisabled={deleteConfirmText !== collection?.name}
        icon={faSkullCrossbones}
        width="w-1/3"
        disabled={isCollectionDeleting}
        onClose={() => {
          setIsShowDeleteModal(false);
          setDeleteConfirmText('');
        }}
        onConfirm={() => handleOnDelete()}>
        <div className="w-full">
          <div>
            <div>
              Are you sure you want to delete this collection? This process is irreversible and{' '}
              <span className="font-extrabold">all data will be lost</span>.
            </div>
            <ul className="list-disc ml-4 mt-4 text-sm">
              <li>Collection will be removed.</li>
              <li>All collection users will loose access to all associated prompts.</li>
            </ul>
            <div className="mt-4">
              To continue, please type &quot;<span className="italic">{collection?.name}</span>&quot; below.
            </div>
            <div className="mt-2">
              <input
                type="text"
                autoComplete="off"
                value={deleteConfirmText}
                placeholder={collection?.name}
                onChange={(e) => setDeleteConfirmText(e.currentTarget.value)}
                required
                disabled={isCollectionDeleting}
                className="disabled:text-gray-400 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 my-5"
              />
            </div>
          </div>
        </div>
      </StyledDialog>
    </>
  );
};

export default ManageCollection;
