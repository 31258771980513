import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { getErrorMessage } from '../../../common/utils';
import { getCollections } from '../../../services/Collections';
import { Collection } from '../../../types';
import CollectionTile from './CollectionTile';

interface Props {
  active?: boolean;
  refreshTrigger?: number;
  onCollectionSelect?: (collectionId: string | undefined) => void;
}

const CollectionList: React.FC<Props> = ({ refreshTrigger, active = true, onCollectionSelect }: Props) => {
  const [collections, setCollections] = useState<Collection[]>([]);
  const [selectedCollection, setSelectedCollection] = useState<Collection>();

  useEffect(() => {
    if (!active) {
      return;
    }

    (async () => {
      try {
        setCollections(await getCollections());
      } catch (error) {
        return toast.error(getErrorMessage(error));
      }
    })();
  }, [active, refreshTrigger]);
  const handleCollectionClick = (collection: Collection) => {
    const _collection = collection.id === selectedCollection?.id ? undefined : collection;

    setSelectedCollection(_collection);
    onCollectionSelect && onCollectionSelect(_collection ? collection.id : undefined);
  };

  return collections.length ? (
    <div>
      <h2 className="text-lg font-semibold text-gray-700 mb-1 mt-6">Collections</h2>
      <ul className="grid xl:grid-cols-6 lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 gap-4 rounded-lg">
        {collections.map((collection) => (
          <CollectionTile
            key={collection.id}
            collection={collection}
            selected={collection.id! === selectedCollection?.id}
            onClick={handleCollectionClick}
          />
        ))}
      </ul>
    </div>
  ) : null;
};

export default CollectionList;
