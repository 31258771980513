import { faCircleUser } from '@fortawesome/free-regular-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { StyledDialog } from '..';
import { getErrorMessage } from '../../common/utils';
import useDebounce from '../../hooks/useDebounce';
import { manageUserSelectorValues } from '../../pages/prompts/ManagePrompt';
import { getUsersAutoComplete } from '../../services/User';
import { Roles, UserBasicInfo } from '../../types';
import Selector, { SelectorValue } from '../common/Selector';

/**
 * Props for the AddUserModal component.
 */
interface Props {
  isOpen: boolean;
  existingEmails?: string[];
  isDisabled?: boolean;
  isSaving?: boolean;
  onClose: () => void;
  onSave: (email: string, role: Roles) => void;
}

/**
 * Modal component for adding a user to the prompt.
 *
 * @component
 * @param {Props} props - The component props.
 * @param {Prompt} props.prompt - The prompt object.
 * @param {boolean} props.isOpen - Flag indicating whether the modal is open.
 * @param {boolean} props.isDisabled - Flag indicating whether the modal is disabled.
 * @param {Function} props.onClose - Function to handle modal close event.
 * @param {Function} props.onSave - Function to handle save event.
 * @returns {JSX.Element} The rendered component.
 */
const AddUserModal: React.FC<Props> = ({
  isOpen,
  existingEmails = [],
  isSaving = false,
  isDisabled = false,
  onClose,
  onSave
}: Props) => {
  const [email, setEmail] = useState<string>('');
  const [matchedUsers, setMatchedUsers] = useState<UserBasicInfo[]>([]);
  const [selectedRole, setSelectedRole] = useState<SelectorValue>(manageUserSelectorValues.READ_WRITE);

  const debouncedEmail = useDebounce(email, 100);

  useEffect(() => {
    if (!email.length) {
      setMatchedUsers([]);
      return;
    }
    console.log({ debouncedEmail, email, matchedUsers });
    if (matchedUsers.length === 1 && matchedUsers[0].email === debouncedEmail) {
      setMatchedUsers([]);
      return;
    }

    (async () => {
      try {
        setMatchedUsers(
          await (await getUsersAutoComplete(debouncedEmail)).filter((user) => !existingEmails.includes(user.email))
        );
      } catch (e) {
        toast.error(getErrorMessage(e));
      }
    })();
  }, [debouncedEmail]);

  useEffect(() => {
    if (!isOpen) {
      setEmail('');
      setSelectedRole(manageUserSelectorValues.READ_WRITE);
      setMatchedUsers([]);
    }
  }, [isOpen]);

  const handleOnClose = () => {
    isOpen = false;
    onClose();
  };

  const handleOnCreate = async () => {
    // const role = selectedRole.value === 1 ? Roles.OWNER : selectedRole.value === 2 ? Roles.READ_ONLY : Roles.READ_WRITE;
    let role: Roles | undefined;
    Object.entries(manageUserSelectorValues).forEach(([key, value]) => {
      if (value === selectedRole) {
        role = key as Roles;
      }
    });

    if (!role) {
      toast.error('Invalid role');
      return;
    }

    onSave(email, role);
  };

  return (
    <StyledDialog
      isOpen={isOpen}
      title="Add User"
      closeText="Cancel"
      width="w-1/4"
      confirmText={
        isSaving ? (
          <div>
            <FontAwesomeIcon icon={faSpinner} className="animate-spin" /> Saving
          </div>
        ) : (
          'Add'
        )
      }
      onConfirm={handleOnCreate}
      onClose={handleOnClose}
      icon={faCircleUser}
      disabled={isDisabled}>
      <div className={`mt-2 w-full flex flex-col ${matchedUsers.length ? 'h-56' : ''}`}>
        <div className="relative w-full">
          <input
            type="email"
            autoComplete="off"
            value={email}
            placeholder="User Email"
            onChange={(e) => setEmail(e.currentTarget.value)}
            required
            disabled={isDisabled}
            className="disabled:text-gray-400 block w-full rounded-md border-0 pt-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-5"
          />
          {matchedUsers.length > 0 && !(matchedUsers.length === 1 && matchedUsers[0].email === email) && (
            <ul className="bg-white border-[1px] border-t-0 rounded-t-none rounded-lg shadow-lg p-4 absolute max-h[200px] overflow-y-auto z-10 w-full">
              {matchedUsers.map((user) => (
                <li
                  key={user.id}
                  className="cursor-pointer first:min-h-9 min-h-10 w-full border-b-[1px] border-solid border-l-gray-300 first:pt-0 pt-2 pb-2"
                  onClick={() => {
                    console.log(user);
                    setEmail(user.email);
                  }}>
                  {user.email}
                </li>
              ))}
            </ul>
          )}
        </div>
        <Selector
          classNames="w-40 my-2"
          values={Object.values(manageUserSelectorValues)}
          defaultValue={selectedRole}
          onChange={(action) => setSelectedRole(action)}
        />
      </div>
    </StyledDialog>
  );
};

export default AddUserModal;
