import { faPenToSquare, faStar } from '@fortawesome/free-regular-svg-icons';
import { faCodeFork, faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { formatRole, isLibraryItem, userCanManageLibrary } from '../../common/prompts';
import { getUserLocal } from '../../services/User';
import { Prompt, UserBasicInfo } from '../../types';
import { UserRole } from '../../types/User';

/**
 * Props for the PromptListRow component.
 */
interface Props {
  prompt?: Prompt;
  onClick?: (prompt: Prompt) => void;
  onManageClick?: (prompt: Prompt) => void;
  onForkClick?: (prompt: Prompt) => void;
  onStarClick?: (prompt: Prompt) => void;
}

/**
 * Renders a prompt list row component.
 *
 * @component
 * @param {Props} props - The component props.
 * @param {Prompt} props.prompt - The prompt object to display.
 * @param {Function} props.onClick - The click event handler for the tile.
 * @returns {JSX.Element} The rendered PromptListRow component.
 */
const PromptListRow: React.FC<Props> = ({ prompt, onManageClick, onForkClick, onClick, onStarClick }: Props) => {
  const [user, setUser] = useState<UserBasicInfo>();

  useEffect(() => {
    const _user = getUserLocal();
    setUser(_user);
  }, [prompt]);

  const handleManageClick = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    onManageClick?.(prompt!);
  };

  const handleForkClick = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    onForkClick?.(prompt!);
  };

  const handleStarClick = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    onStarClick?.(prompt!);
  };

  return (
    <li className="px-6 py-3 hover:cursor-pointer" onClick={() => onClick && onClick(prompt!)}>
      <div className="flex items-center justify-between w-full">
        <h3 className="text-gray-800 overflow-hidden">
          <span className="overflow-hidden whitespace-nowrap text-ellipsis inline">
            {prompt ? prompt.name : <Skeleton />}
          </span>
        </h3>
        <div className="ml-2 min-w-8">
          {!prompt ? (
            <Skeleton />
          ) : (
            <div className="flex gap-x-2 text-gray-500 ">
              <FontAwesomeIcon
                icon={prompt.isFavorite ? faStarSolid : faStar}
                className={`${prompt.isFavorite ? 'text-yellow-500 hover:text-gray-500' : 'hover:text-yellow-500'}`}
                onClick={handleStarClick}
              />
              <FontAwesomeIcon
                icon={faCodeFork}
                className="hover:text-indigo-800"
                title="Fork Prompt"
                onClick={handleForkClick}
              />
              {user && (!isLibraryItem(prompt) || userCanManageLibrary(prompt, user)) && (
                <FontAwesomeIcon
                  icon={faPenToSquare}
                  className="hover:text-indigo-800"
                  title="Manage Prompt"
                  onClick={handleManageClick}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <h4 className="text-sm text-gray-500 mt-0 w-full">
        {user?.role !== UserRole.ADMIN ? formatRole(prompt?.userRole!) : prompt?.userName}
      </h4>
      <h4 className="text-sm text-gray-600 mt-2 max-h-5 w-full word-break-all line-clamp-1">
        {prompt ? prompt.description : <Skeleton />}
      </h4>
    </li>
  );
};

export default PromptListRow;
