import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { faLink, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import { SHARED_PROMPT_PATH } from '../../constants';
import { enableVersionShare } from '../../services/PromptVersions';
import { PromptVersion } from '../../types';
import { StyledDialog } from '../common';

/**
 * Props for the PromptCodeBlock component.
 */
interface Props {
  version?: PromptVersion;
  isOpen: boolean;
  onClose: () => void;
}

const BASE_URL = `${window.location.protocol}//${window.location.host}${SHARED_PROMPT_PATH}`;

const PromptShareModal: React.FC<Props> = ({ version, isOpen, onClose }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [shareUrl, setShareUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!version || !isOpen) return;

    setIsLoading(true);
    (async () => {
      if (version?.shareId) {
        setShareUrl(`${BASE_URL}/${version.shareId}`);
        setIsLoading(false);

        return;
      }

      try {
        const shareId = await enableVersionShare(version.promptId, version.version);
        setShareUrl(`${BASE_URL}/${shareId}`);
        setIsLoading(false);
      } catch (error) {
        toast.error('Failed to enable sharing for this prompt.');
        onClose();
      }
    })();
  }, [version, isOpen]);

  return (
    <StyledDialog title="Copy Link" isOpen={isOpen} icon={faLink} onClose={() => onClose()}>
      <div className="text-sm">
        <div>Use the link below to share this prompt with others.</div>
        <div className="mt-1 text-xs">
          {isLoading ? (
            <Skeleton count={2} />
          ) : (
            <div>
              <a href={shareUrl} target="_blank" rel="noreferrer">
                {shareUrl}
              </a>
              <span className="ml-2 cursor-pointer hover:text-indigo-500">
                <CopyToClipboard
                  text={shareUrl || ''}
                  onCopy={() => {
                    toast.success('URL copied to clipboard!');
                  }}>
                  <FontAwesomeIcon icon={faCopy} className="h-4 w-4 mr-1 inline-block" />
                </CopyToClipboard>
              </span>
            </div>
          )}
        </div>
        <div className="text-xs mt-5">
          <FontAwesomeIcon icon={faTriangleExclamation} className="text-yellow-400 pr-1.5" /> Please note this link is
          public and can be accessed by anyone.
        </div>
      </div>
    </StyledDialog>
  );
};

export default PromptShareModal;
