import { faFolderClosed, faFolderOpen, faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router';
import { getUserLocal } from '../../../services/User';
import { Collection } from '../../../types';

/**
 * Props for the CollectionTile component.
 */
interface Props {
  collection?: Collection;
  onClick?: (collection: Collection) => void;
  selected?: boolean;
}

/**
 *
 * Renders a collection tile component.
 *
 * @component
 * @param {Props} props - The component props.
 * @param {Collection} props.collection - The collection object to display.
 * @param {Function} props.onClick - The function to call when the tile is clicked.
 * @param {boolean} props.selected - Whether the tile is selected.
 * @returns {JSX.Element} The rendered CollectionTile component.
 */
const CollectionTile: React.FC<Props> = ({ collection, onClick, selected = false }: Props) => {
  const navigate = useNavigate();

  const handleManageClick = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    if (!collection) return;

    navigate(`/collections/${collection.id}`, { state: { collection } });
  };

  return (
    <li
      className={`group rounded-lg border-gray-300 border p-3 shadow-lg ${!selected ? 'hover:border-indigo-600' : 'border-indigo-600'} hover:cursor-pointer col-span-1`}
      onClick={() => onClick && onClick(collection!)}>
      <div className="flex items-center justify-between w-full">
        <h3 className="text-gray-800 overflow-hidden">
          <FontAwesomeIcon
            icon={selected ? faFolderOpen : faFolderClosed}
            className="inline w-4 h-4 text-indigo-500 mr-2"
          />
          <span className="whitespace-nowrap inline overflow-ellipsis">
            {collection ? collection.name : <Skeleton />}
          </span>
        </h3>
        <div className="ml-4 w-8 text-right">
          {collection ? (
            collection.owners.find((owner) => owner.id === getUserLocal()?.id) !== undefined && (
              <FontAwesomeIcon
                icon={faGear}
                className="text-gray-500 group-hover:text-indigo-600"
                onClick={handleManageClick}
              />
            )
          ) : (
            <Skeleton containerClassName="inline" />
          )}
        </div>
      </div>
      <div className="text-sm text-gray-600 mt-2">
        {collection ? (
          `${collection.promptCount} Prompt${collection.promptCount !== 1 ? 's' : ''}`
        ) : (
          <Skeleton containerClassName="inline" />
        )}
      </div>
    </li>
  );
};

export default CollectionTile;
